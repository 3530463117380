<script context="module" lang="ts">
	export declare namespace TextNamespace {
		type HeadingType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
		type TextType = 'p' | HeadingType | 'span';
		type Props = {
			align?: 'left' | 'center' | 'right';
			type?: TextType;
			transform?: 'uppercase' | 'lowercase' | 'capitalize';
			colour?: TColours;
			italic?: boolean;
			thin?: boolean;
			medium?: boolean;
			semiBold?: boolean;
			bold?: boolean;
			font?: 'domine';
			lead?: '0' | '1' | 'small';
			decoration?: 'underline' | 'line-through' | 'overline' | 'none';
			id?: string;
			children: Snippet;
			'data-testid'?: string;
		};
	}
</script>

<script lang="ts">
	import type { TColours } from '$lib/data/colours';
	import classNames from 'classnames';
	import { getContext, setContext, type Snippet } from 'svelte';

	const {
		align,
		type = 'p',
		transform,
		colour = 'secondary-black',
		italic = false,
		thin = false,
		medium = false,
		semiBold = false,
		bold = false,
		font,
		lead,
		decoration,
		id,
		children,
		...restProps
	}: TextNamespace.Props = $props();

	const textContext = getContext<{ type: TextNamespace.TextType }>('Text');

	setContext('Text', {
		type,
	});

	let finalType = $derived(textContext?.type ? 'span' : type);

	const classes = $derived(
		classNames('Text', {
			[`Text--align-${align}`]: align,
			[`Text--colour-${colour}`]: colour,
			[`Text--decoration-${decoration}`]: decoration,
			[`Text--transform-${transform}`]: transform,
			[`Text--font-${font}`]: font,
			[`Text--lead-${lead}`]: lead,
			'Text--italic': italic,
			'Text--thin': thin,
			'Text--medium': medium,
			'Text--semiBold': semiBold,
			'Text--bold': bold,
			'Text--inherit': Boolean(textContext?.type),
		}),
	);

	const appliedProps = $derived({
		class: classes,
		id,
		...restProps,
	});
</script>

{#if finalType === 'p'}
	<p {...appliedProps}>
		{@render children()}
	</p>
{:else if finalType === 'span'}
	<span {...appliedProps}>
		{@render children()}
	</span>
{:else if finalType === 'h1'}
	<h1 {...appliedProps}>
		{@render children()}
	</h1>
{:else if finalType === 'h2'}
	<h2 {...appliedProps}>
		{@render children()}
	</h2>
{:else if finalType === 'h3'}
	<h3 {...appliedProps}>
		{@render children()}
	</h3>
{:else if finalType === 'h4'}
	<h4 {...appliedProps}>
		{@render children()}
	</h4>
{:else if finalType === 'h5'}
	<h5 {...appliedProps}>
		{@render children()}
	</h5>
{:else if finalType === 'h6'}
	<h6 {...appliedProps}>
		{@render children()}
	</h6>
{/if}

<style lang="scss">
	@import '$lib/styles/colours.scss';
	p {
		font-size: 1rem;
		:global(span) {
			font-size: 1rem;
		}
	}

	h1,
	h2,
	h3,
	h4 {
		&.Text {
			&--thin {
				font-weight: 400;
			}

			&--medium {
				font-weight: 500;
			}

			&--semiBold {
				font-weight: 600;
			}
		}
	}

	span {
		&:not(.Text--inherit) {
			font-size: 0.75rem;
		}
	}

	span,
	p {
		&.Text {
			&--semiBold {
				font-weight: 500;
			}

			&--bold {
				font-weight: 600;
			}
		}
	}

	.Text {
		&--inherit {
			font-family: inherit;
			color: inherit;
			font-weight: inherit;
		}

		&--italic {
			font-style: italic;
		}

		&--align-left {
			text-align: left;
		}

		&--align-center {
			text-align: center;
		}

		&--align-right {
			text-align: right;
		}

		&--lead {
			&-0 {
				line-height: 0;
			}

			&-1 {
				line-height: 1;
			}

			&-small {
				line-height: 1.2;
			}
		}

		&--colour {
			@each $colour in $colours {
				&-#{$colour} {
					color: var(--#{$colour});
				}
			}
		}

		&--decoration {
			&-underline {
				text-decoration: underline;
			}

			&-line-through {
				text-decoration: line-through;
			}

			&-overline {
				text-decoration: overline;
			}

			&-none {
				text-decoration: none;
			}
		}

		&--transform {
			&-uppercase {
				text-transform: uppercase;
			}

			&-lowercase {
				text-transform: lowercase;
			}

			&-capitalize {
				text-transform: capitalize;
			}
		}

		&--font {
			&-domine {
				font-family: 'Domine', serif;
			}
		}
	}
</style>
